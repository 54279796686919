/**
 * Provide abstracted access to browser functions.
 * This will allow for easy mocking, as well as save
 * access to the `window` object
 */

// Small hack to make this work in unit-testing
if (global && !global.window) {
  global.window = {}
}

const myWindow = window || global.window
// End of hack

export const {
  location
} = myWindow

export function requestAnimationFrame (...args) {
  return myWindow.requestAnimationFrame(...args)
}

export function addEventListener (...args) {
  return myWindow.addEventListener(...args)
}

/**
 * Assign a function or object to a property on the window object
 *
 * @param {*} object Any object or function you want to assign to `window`
 * @param {string} name Name of the property you want to assign to
 * @returns {*} The object you assigned so you can chain or assign locally
 */
export function assignToWindow (object, name) {
  myWindow[name] = object
  return object
}
