<template>
  <div class="price-box"
       v-if="showPrices"
       data-testid="price-box">
    <ProtectedPrice class="line-item__price price"
      :price="price" :shouldBlur="shouldBlur"
      data-testid="price"/>
    <ProtectedPrice class="line-item__price old-price"
      v-if="lineItem.oldPrice && !showConsumerAdvicePrice"
      :price="lineItem.oldPrice" :shouldBlur="shouldBlur"
      data-testid="old-price"/>
    <span class="badge badge--discount badge--small"
          v-if="isUseSimulatedPrice && hasDiscountValid(lineItem)"
          data-testid="discount-badge">
      - {{ lineItem.discountPercentage }}%
    </span>
    <dl class="price-box__summary" v-if="lineItem.battery_required">
      <dt class="price-box__summary-label">{{ $t(BIKE) }}</dt><dd class="price-box__summary-price">
        <ProtectedPrice class="line-item__price price"
          :price="price" :shouldBlur="shouldBlur"
          data-testid="price"/>
      </dd>
      <dt class="price-box__summary-label">{{ $t(BATTERY) }}</dt><dd class="price-box__summary-price">
        <ProtectedPrice class="line-item__price price"
          :price="batteryPrice" :shouldBlur="shouldBlur"
          data-testid="price"/>
      </dd>
      <dt class="price-box__summary-label">{{ $t(TOTAL) }}</dt><dd class="price-box__summary-price">
        <ProtectedPrice class="line-item__price price"
          :price="combinedPrice" :shouldBlur="shouldBlur"
          data-testid="price"/>
      </dd>
    </dl>
  </div>
</template>
<script setup>
import { inject, ref, computed } from 'vue'
import { lineItemProps } from '@/helpers/props'
import { getPrice, getBatteryPrice, getCombinedPrice, hasDiscountValid } from '@/helpers/lineItem.js'
import ProtectedPrice from '@/components/atoms/ProtectedPrice/ProtectedPrice.vue'
import { BATTERY, BIKE, TOTAL } from '@/i18n/constants'

const settings = inject('settings', {})

const props = defineProps({
  lineItem: lineItemProps,
  shouldBlur: Boolean
})

const price = computed(() => getPrice(props.lineItem, settings.isUseSimulatedPrice))
const batteryPrice = computed(() => getBatteryPrice(props.lineItem, settings.isUseSimulatedPrice))
const combinedPrice = computed(() => getCombinedPrice(props.lineItem, settings.isUseSimulatedPrice))
const showPrices = ref(settings.showPrices)
const isUseSimulatedPrice = ref(settings.isUseSimulatedPrice)
const showConsumerAdvicePrice = ref(settings.showConsumerAdvicePrice)

</script>
