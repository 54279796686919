import { inject, reactive } from 'vue'
import { PRICE_DISPLAY } from '@/constants/magentoSettings'

const SETTINGS_MAP = {
  addresses: (localStore, value) => {
    localStore.addresses = value ?? []
    localStore.defaultAddress = localStore.addresses[0] ?? null
  },
  brand: (localStore, value) => {
    localStore.brand = value
  },
  cart_url: (localStore, value) => {
    localStore.cartURL = value
  },
  currency_code: (localStore, value) => {
    localStore.currency = value
  },
  locale: (localStore, value) => {
    localStore.locale = value
  },
  price_display: (localStore, value) => {
    localStore.priceDisplay = value
    if (value === PRICE_DISPLAY.hidePrices) {
      localStore.showPrices = false
    } else {
      localStore.showPrices = true
    }
  },
  is_showroom_mode_enabled: (localStore, value) => {
    localStore.isShowroomModeEnabled = value
  },
  company_id: (localStore, value) => {
    localStore.companyId = value
  },
  website_code: (localStore, value) => {
    localStore.channel = value
  },
  factor_margin: (localStore, value) => {
    localStore.factorMargin = value
  },
  product_tax: (localStore, value) => {
    localStore.productTax = value
  },
  delivery_weeks: (localStore, value) => {
    localStore.deliveryWeeks = value.map(week => ({ ...week, weekno: parseInt(week.label, 10), year: 'TODO' }))
  },
  is_sold_enabled: (localStore, value) => {
    localStore.isSoldEnabled = value
  },
  is_pricing_and_availability_available: (localStore, value) => {
    localStore.isPricingAndAvailabilityAvailable = Boolean(value)
  },
  // Customer Rules
  customer_rules: (localStore, value) => {
    value.forEach((localValue) => {
      const {
        rule,
        is_allowed: isAllowed
      } = localValue
      const setter = SETTINGS_MAP[rule]
      if (setter) {
        setter(localStore, isAllowed)
      }
    })
  },
  'checkout:order:submit': (localStore, value) => {
    localStore.customerRules.isOrderSubmitAllowed = value
  },
  'product:price:view': (localStore, value) => {
    localStore.customerRules.isViewPriceAllowed = value
  },
  'product:price:dealer': (localStore, value) => {
    localStore.customerRules.isDealerPriceAllowed = value
  }
}

const configNotEnabled = async () => Promise.reject(new Error('Not Yet Configured'))

const magentoSettings = reactive({
  settingsLoaded: false,
  addresses: [],
  brand: null,
  cartURL: null,
  channel: null,
  currency: 'EUR',
  defaultAddress: null,
  delivery_weeks: [],
  locale: 'en_US',
  priceDisplay: null,
  isPricingAndAvailabilityAvailable: true,
  isShowroomModeEnabled: false,
  hasSimulatedPrices: false,
  companyId: null,
  factorMargin: 1,
  productTax: 1,
  customerRules: {
    isViewPriceAllowed: true,
    isDealerPriceAllowed: true,
    isOrderSubmitAllowed: true
  },

  /*
  Price modes (calculated based on `priceDisplay` and `isShowroomModeEnabled`). In theory, only one of these
  can be `true` at any given time, expect exceptions ;)
  Three ouput cases exist:
  1 Hide prices (showPrices == false) (no price, no icons, no discount-label)
  2 Show 'recommended retail prices' (Prices in CAP and show icons, no discount-label)
  3 Show all meta-data, blur prices until order-simulate is done (Order-simulate prices, show icons, discount-label yes)
  */
  showPrices: true, // case 1
  showConsumerAdvicePrice: false, // case 2, AKA (Recommended) Retail Price, AKA Consumenten Advies Prijs
  isUseSimulatedPrice: false, // case 3 AKA Purchase Price

  fireGtmEvent: configNotEnabled,
  fetchTranslations: configNotEnabled,
  fetchToken: configNotEnabled,
  fetchSettings: configNotEnabled,
  parseSettings: function (settings) {
    magentoSettings.settingsLoaded = true
    Object.keys(settings).forEach((key) => {
      const remapKeyValue = SETTINGS_MAP[key]
      if (SETTINGS_MAP[key]) {
        remapKeyValue(magentoSettings, settings[key])
      }
    })
    setComputedPriceModes()
  }
})

function setComputedPriceModes () {
  magentoSettings.showPrices = magentoSettings.showPrices && magentoSettings.customerRules.isViewPriceAllowed
  setShowConsumerAdvicePrice()
  setIsUseSimulatedPriceSetting()
}

/**
 * This should take place after settings have been mapped, as it depends on `isShowroomModeEnabled` and `priceDisplay`
 */
function setIsUseSimulatedPriceSetting () {
  if (magentoSettings.isShowroomModeEnabled || !magentoSettings.customerRules.isViewPriceAllowed) {
    magentoSettings.isUseSimulatedPrice = false
    return
  }

  switch (magentoSettings.priceDisplay) {
    case PRICE_DISPLAY.purchasePrices:
      magentoSettings.isUseSimulatedPrice = magentoSettings.customerRules.isDealerPriceAllowed
      break
    case PRICE_DISPLAY.retailPrices:
      magentoSettings.isUseSimulatedPrice = false
      break
  }
}

function setShowConsumerAdvicePrice () {
  // Never true if any settings says no
  const allowConsumerAdvicePrice =
    magentoSettings.priceDisplay !== PRICE_DISPLAY.hidePrices &&
    magentoSettings.customerRules.isViewPriceAllowed

  magentoSettings.showConsumerAdvicePrice =
    allowConsumerAdvicePrice && (
      magentoSettings.isShowroomModeEnabled ||
      magentoSettings.priceDisplay === PRICE_DISPLAY.retailPrices ||
      magentoSettings.customerRules.isDealerPriceAllowed === false
    )
}

export function useSettings () {
  return inject('settings', /** @type {typeof magentoSettings} */ ({}))
}

export default magentoSettings
