import { PRODUCT_GROUPS, STOCK_STATUS } from '@/constants/lineItem'
import { PRODUCTION_WEEK, PRODUCTION_MONTH } from '@/i18n/constants.js'

const inProductionKey = Object.keys(STOCK_STATUS).find(key => STOCK_STATUS[key] === 'in_production')

/**
 * @typedef {object} LineItem
 * @property {boolean} battery_required - Indicates if a battery is required.
 * @property {string} brand - The brand of the bike.
 * @property {string} channel - The channel identifier.
 * @property {string} deliveryWeek - The delivery week identifier.
 * @property {string} ean - The European Article Number.
 * @property {boolean} ebike - Indicates if it is an eBike.
 * @property {string} ebike_system - The eBike system details.
 * @property {string} erpAddressId - ERP Address ID.
 * @property {string} erpCompanyId - ERP Company ID.
 * @property {string} frame_size_label - Label for frame size.
 * @property {number} id - Unique identifier of the bike object.
 * @property {string} language - The language code.
 * @property {string} material_number - Material number.
 * @property {string} name - The name of the product.
 * @property {string} note - Additional notes.
 * @property {number} price - The price of the bike.
 * @property {string} product_group - The product group.
 * @property {number} quantity - The quantity of the product.
 * @property {string} rrp - Recommended retail price.
 * @property {string} shortSku - Short SKU.
 * @property {string} sku - SKU.
 * @property {string} stock_status - Stock status identifier.
 * @property {string} stock_status_label - Label for stock status.
 * @property {number} timestamp - Timestamp of the object.
 * @property {string} url_key - URL key for the product.
 */

/**
 * @typedef {object} GoogleAnalyticsItem
 * @property {string} item_name - The name of the item.
 * @property {string} item_id - The unique identifier for the item.
 * @property {string} price - The price of the item.
 * @property {string} item_brand - The brand of the item.
 * @property {string=} item_category - The main category of the item.
 * @property {string=} item_category2 - The secondary category of the item.
 * @property {string=} item_category3 - The third category of the item.
 * @property {string=} item_category4 - The fourth category of the item.
 * @property {string=} item_variant - The variant of the item (e.g., color, size).
 * @property {string} quantity - The quantity of the item.
 */

export function parse (price) {
  return (price && price.constuctor !== Number) ? parseFloat(price) : /** @type {number} */ (price)
}

export function isBike (item) { return item.product_group === PRODUCT_GROUPS.bike }

export function hasDiscountValid (item) { return item.hasDiscount && item.discountPercentage > 0 }

export function getDiscountIcons (item) {
  if (item.discountIcon === false) return []
  const classes = ['discount-icon', 'discount-icon--rounded']
  let discountType = ''
  if (item.discountIcon === 'simple') {
    discountType = 'simple'
  }
  if (item.discountIcon === 'tier') {
    discountType = 'tier'
  }
  if (item.discountIcon === 'cumulatedTier') {
    discountType = 'tier'
  }

  return [{ class: classes, hasDiscount: 1, discountType }]
}

export function getPrice (item, isUseSimulatedPrice = false) {
  if (isUseSimulatedPrice) {
    return parse(item.simulatedPrice?.price_unit ?? 0)
  }
  if (isBike(item) || hasDiscountValid(item)) {
    return parse(item.rrp)
  }

  return parse(item.displayRRP)
}

export function getBatteryPrice (item, isUseSimulatedPrice = false) {
  if (!isBike(item)) {
    return -1
  }
  if (isUseSimulatedPrice) {
    return parse(item.simulatedPrice?.battery_unit ?? 0)
  }
  const batteryInfo = item.battery_info?.find((info) => info.material_number === item.batterySku)
  if (!batteryInfo || !batteryInfo.rrp) {
    // eslint-disable-next-line no-console
    console.warn('Battery price is missing')
  }
  return parse(batteryInfo?.rrp ?? 0)
}

export function getCombinedPrice (item, isUseSimulatedPrice = false) {
  if (!isBike(item)) {
    return -1
  }
  if (isUseSimulatedPrice) {
    return parse(item.simulatedPrice?.combined_unit ?? 0)
  }

  return parse(item.combined_rrp ?? 0)
}

export function getProductAttributes (item) {
  return [
    {
      title: '',
      value: item?.color
    },
    {
      title: item?.frame_size_label,
      value: item?.frame_size
    },
    {
      title: '',
      value: item?.gender_frame
    }
  ]
}

export function getStockStatusId (item) { return STOCK_STATUS[item.stock_status] ?? '' }

export function getStockStatusLabel (item) {
  if (item.stock_status === inProductionKey && item?.production_week) {
    return { text: PRODUCTION_WEEK, indication: item.production_week }
  }
  if (item.stock_status === inProductionKey && item?.production_month) {
    return { text: PRODUCTION_MONTH, indication: item.production_month }
  }
  return item?.stock_status_label ?? ''
}

export function getUrl (item) { return `/catalog_product/redirect/byProductSku/sku/${item.sku}` }

/**
 * Transform an internal line-item into a Google Analytics compatible line-item.
 *
 * @param {LineItem} item An internal representation of a lineItem
 * @returns {GoogleAnalyticsItem}
 */
export function getAnalyticsLineItem (item) {
  return {
    item_name: item.name,
    item_id: item.sku,
    price: getPrice(item).toFixed(2), // Google requires two decimal places, no more, no less
    item_brand: item.brand,
    item_category: item.product_group,
    item_category2: item.ebike_system,
    item_category3: item.stock_status_label,
    quantity: item.quantity.toFixed(0) // No decimal points allowed
  }
}
