<template>
  <div class="custom-control custom-checkbox" data-testid="line-item-group-selector__item">
    <input class="custom-control-input"
           type="checkbox"
           :id="inputId"
           v-model="lineItemGroup.checked"
           :disabled="!hasCount">
    <label class="custom-control-label text-muted"
           :data-testid="elementTestId"
           :for="inputId">
      {{ t(ORDER_1).replace('%1', groupTitle) }} ({{ hasCount }})
    </label>
  </div>
</template>
<script setup>
import { computed, inject, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { lineItemGroupProps } from '@/helpers/props'
import { ORDER_1 } from '@/i18n/constants'

const { t } = useI18n({ useScope: 'global' })
const props = defineProps({
  lineItemGroup: lineItemGroupProps
})
const cartStore = inject('cartStore')
const settings = inject('settings', {})
const elementTestId = `line-item-group-selector__item--${props.lineItemGroup.title.toLowerCase()}`
const groupTitle = t(props.lineItemGroup.title).toLocaleLowerCase()
const hasCount = computed(() => cartStore.lines[props.lineItemGroup.type].count)
const inputId = `cartSummary${props.lineItemGroup.type}`

watch(props.lineItemGroup, changeStatus)

function changeStatus () {
  if (cartStore.simulatedTotals) {
    settings.hasSimulatedPrices = false
  }
}

</script>
