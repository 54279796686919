<template>
  <Teleport to="body">
    <div id="cartman" class="cart-page cart-page--modal" :class="{
      loading: cartStore.isLoading === true,
      loaded: cartStore.isLoading === false,
      collapse: cartStore.isModalOpen === false,
      collapsed: cartStore.isModalOpen === true
    }" data-testid="cart-page">
      <div class="container">
        <header class="page-section__header">
          <h1 class="page-section__title" data-testid="cartman__page-section__title">{{ $t(CART) }}</h1>
        </header>
        <AddressSwitcher />
        <div class="centered-form aligner aligner--center-aligned" :class="{ 'd-none': cartStore.isLoading === false }">
          <Loader class="aligner__item" />
        </div>
        <section class="cart-page__columns" v-if="cartStore.count" :class="{ 'd-none': cartStore.isLoading === true }">
          <main class="cart-page__main-column cart-page__column">
            <CartLineItemGroups class="cart-page__panel" />
            <footer class="cart-page__footer">
              <EmptyCart />
            </footer>
          </main>
          <aside class="cart-page__sidebar cart-page__column">
            <FinalizePane class="cart-page__panel" @cart:submit="cartSubmit" />
          </aside>
        </section>
      </div>
    </div>
  </Teleport>
</template>
<script setup>
import { inject, watch } from 'vue'
import { CART } from '@/i18n/constants'
import AddressSwitcher from '@/components/organisms/Cart/AddressSwitcher/AddressSwitcher.vue'
import CartLineItemGroups from '@/components/organisms/Cart/LineItemGroups/LineItemGroups.vue'
import FinalizePane from '@/components/organisms/Cart/FinalizePane/FinalizePane.vue'
import EmptyCart from '@/components/organisms/Cart/EmptyCart/EmptyCart.vue'
import { Loader } from '@ponbike/vue-components'

const cartStore = inject('cartStore')

const emit = defineEmits(['order:submit'])
watch(
  () => cartStore.isModalOpen,
  (isModalOpen) => {
    isModalOpen ? document.body.classList.add('disable-scroll') : document.body.classList.remove('disable-scroll')
  }
)

function cartSubmit (value) {
  emit('order:submit', value)
}

</script>
