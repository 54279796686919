<template>
  <article class="cartman-mini-basket ponbike-icon ponbike-icon--cart" @click="toggleCart">
    <em :apiKey="apiKey" class="minicart__count badge badge--info badge--rounded-xs badge--rounded badge--offset"
      :class="xtraClasses" data-testid="minicart__count">
      {{ (!cartStore.isApiDown) ? cartStore.totalQuantity: '!' }}
    </em>
    <div class="cartman-empty-dialogue" v-if="showEmpty">
      <p>{{ $t(EMPTY_CART) }}</p>
    </div>
  </article>
</template>
<script setup>
import { inject, ref, watch, computed } from 'vue'
import { EMPTY_CART } from '@/i18n/constants'

const apiKey = inject('apiKey', undefined)
const cartStore = inject('cartStore', { totalQuantity: 0 })
const settings = inject('settings')
const showEmpty = ref(false)
const computeIsEmpty = () => cartStore.totalQuantity <= 0

watch(() => cartStore.totalQuantity, async () => {
  if (showEmpty.value === true && !computeIsEmpty()) showEmpty.value = false
})

const xtraClasses = computed(() => ({
  'badge--cart-loading': cartStore.isLoading,
  'badge--is-down': cartStore.isApiDown
}))

function toggleCart () {
  if (computeIsEmpty()) {
    showEmpty.value = !showEmpty.value
    return
  }

  showEmpty.value = false

  if (settings.cartURL) {
    if (cartStore.isModalOpen) {
      window.history.go(0)
    } else {
      window.history.pushState({ cartModal: true }, '', settings.cartURL)
      cartStore.toggleCartModal()
    }
    return
  }

  cartStore.toggleCartModal()
}

</script>
<style>
.badge--cart-loading {
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
}
.badge--is-down {
  background-color: red;
  color: white;
  content: '!'
}

@keyframes pulse-dot {
  0% {
    transform: translate3d(-10px, -10px, 0) scale(.7);
  }

  50% {
    transform: translate3d(-10px, -10px, 0) scale(1);
  }

  100% {
    transform: translate3d(-10px, -10px, 0) scale(.7);
  }
}
</style>
