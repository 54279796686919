/* eslint-disable jsdoc/valid-types */
/* istanbul ignore file */

// exclude this file from jest coverage as it doesn't contain business logic

/**
 * @enum {typeof LINE_ITEM_GROUPS[keyof typeof LINE_ITEM_GROUPS]}
 */
export const LINE_ITEM_GROUPS = /** @type {const} */({
  bikes: 'bikes',
  parts: 'parts',
  accessories: 'accessories'
})

/**
 * @enum {typeof PRODUCT_GROUPS[keyof typeof PRODUCT_GROUPS]}
 */
export const PRODUCT_GROUPS = /** @type {const} */({
  bike: 'BIKE',
  part: 'PART',
  accessory: 'ACCESSORY'
})

/**
 * @enum {typeof STOCK_STATUS[keyof typeof STOCK_STATUS]}
 */
export const STOCK_STATUS = /** @type {const} */({
  0: 'not_available',
  1: 'available',
  2: 'in_production'
})
