<template>
  <section class="address-switcher" data-testid="address-switcher">
    <AddressSwitcherItem v-for="address in cartStore.addresses" :address="address"/>
  </section>
</template>
<script setup>
import { inject } from 'vue'
import AddressSwitcherItem from './Item/Item.vue'

const cartStore = inject('cartStore')

</script>
