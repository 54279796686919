<template>
    <a class="line-item__action line-item__action--add-note button-group__item ponbike-icon--pencil --invert-on-hover"
       @click.prevent="toggleModal"
       @keyup.escape="closeModal"
       :title="$t(ADD_NOTE)"
       href="#"
       data-testid="line-item__action--add-note"></a>
    <Modal :transitionEnabled="true"
           :open="showModal"
           @close="toggleModal"
           data-testid="line-item__add-note-modal">
      <template #header>
        <h5 class="pon-modal__title">{{ $t(NOTE_FOR) }} {{ lineItem.name }}</h5>
      </template>
      <template #close>
        <span class="pon-modal__close-icon"></span>
      </template>
      <template #body>
        <textarea class="form-control"
                  v-model="lineItem.note"
                  rows="7"
                  cols="5"
                  maxlength="120"
                  name="note"
                  :placeholder="$t(ADD_A_MESSAGE)"></textarea>
        <div class="pon-modal__actions">
          <button class="btn--portal btn--primary btn--small"
                  type="button"
                  @click="onClick">{{ $t(OK) }}</button>
        </div>
      </template>
    </Modal>
</template>
<script setup>
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import { ref, inject } from 'vue'
import { lineItemProps } from '@/helpers/props'
import { Modal } from '@ponbike/vue-components'
import { ADD_A_MESSAGE, ADD_NOTE, NOTE_FOR, OK } from '@/i18n/constants'

const props = defineProps({
  lineItem: lineItemProps
})

const cartStore = inject('cartStore')

const showModal = ref(false)
const closeModal = () => { showModal.value = false }
const toggleModal = () => { showModal.value = !showModal.value }

function onClick () {
  cartStore.actions.changeNote(props.lineItem._id, props.lineItem.note)
    .catch(e => console.error(e))
  closeModal()
}
</script>
