/* eslint max-params: ["error", 6] */
import magentoSettings from '@/stores/magentoSettings'
import urlGenerator from '@/helpers/url-generator'

const API_ROOT = (process.env.VUE_APP_MICRO_SERVICE) ? `//${process.env.VUE_APP_MICRO_SERVICE}/v1` : '//localhost:3456/v1'
export const VERIFICATION_HEADER = 'x-verification'

async function addAuthHeaders (headers) {
  const apiKey = (await magentoSettings.fetchToken()).customer_token
  return {
    ...headers,
    authorization: `Bearer ${apiKey}`
  }
}

async function addJSONHeaders (headers) {
  return {
    ...headers,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
}

export function buildUrl (path, searchParams = {}, url = API_ROOT) {
  return urlGenerator.create({
    url,
    path: (path.constructor === String) ? { [path.replace(/^\/+/g, '')]: null } : path,
    searchParams,
    base: window.location
  })
}

async function checkResponseSuccess (response) {
  if (response.status > 399) {
    throw (response)
  }
  return response
}

export async function getStatus () {
  const url = buildUrl('status')
  return fetch(
    url
  )
}

export async function postProductLines (productLines, status) {
  const headers = await addJSONHeaders().then(addAuthHeaders)
  const { channel, erpCompanyId: company } = productLines[0]
  const url = buildUrl('cart/lines', { channel, company, status })

  return fetch(
    url,
    {
      method: 'POST',
      headers,
      body: JSON.stringify(productLines)
    }
  )
    .then(checkResponseSuccess)
}

export async function orderCart (orderId) {
  const headers = await addJSONHeaders().then(addAuthHeaders)
  const url = buildUrl('order')

  return fetch(
    url,
    {
      method: 'POST',
      headers,
      body: JSON.stringify({
        order_id: orderId
      })
    }
  )
    .then(checkResponseSuccess)
}
export async function orderStatus (path) {
  const headers = await addJSONHeaders().then(addAuthHeaders)
  const url = buildUrl(path)

  return fetch(
    url,
    {
      method: 'GET',
      headers
    }
  )
    .then(checkResponseSuccess)
}

export async function submitCart (address, brand, channel, status, groups) {
  const headers = await addJSONHeaders().then(addAuthHeaders)
  const url = buildUrl('cart/submit', { address, brand, channel, status })
  return fetch(
    url,
    {
      method: 'POST',
      headers,
      body: JSON.stringify(groups)
    }
  )
    .then(checkResponseSuccess)
}

export async function fetchCart (address, brand, channel, company, status = 'open') {
  const headers = await addJSONHeaders().then(addAuthHeaders)
  const url = buildUrl('cart', { address, brand, channel, company, status })

  return fetch(
    url,
    {
      headers
    }
  )
    .then(checkResponseSuccess)
}

export async function updateProperty (address, brand, channel, company, id, updatedProp) {
  const headers = await addJSONHeaders().then(addAuthHeaders)
  const url = buildUrl({ orderline: id }, { address, brand, channel, company })

  return fetch(
    url,
    {
      method: 'PATCH',
      headers,
      body: JSON.stringify(updatedProp)
    }
  )
    .then(checkResponseSuccess)
}

export async function fetchActiveCarts (brand, channel) {
  const headers = await addJSONHeaders().then(addAuthHeaders)
  const url = buildUrl('active', { brand, channel })

  return fetch(
    url,
    {
      headers
    }
  )
    .then(checkResponseSuccess)
}

export async function deleteOrderLine (address, brand, channel, company, status, id) {
  const headers = await addJSONHeaders().then(addAuthHeaders)
  const url = buildUrl({ orderline: id }, { address, brand, channel, company, status })

  return fetch(
    url,
    {
      method: 'DELETE',
      headers
    }
  )
    .then(checkResponseSuccess)
}

export async function deleteCart (address, brand, channel, status) {
  const headers = await addJSONHeaders().then(addAuthHeaders)
  const url = buildUrl('cart', { address, brand, channel, status })
  return fetch(
    url,
    {
      method: 'DELETE',
      headers
    }
  )
    .then(checkResponseSuccess)
}

export async function fetchOrderSimulation ({
  address,
  brand,
  channel,
  companyId,
  status,
  groups = [],
  orderName = 'Consumer order'
}) {
  const headers = await addJSONHeaders().then(addAuthHeaders)
  const url = buildUrl({ cart: 'simulate' }, { address, brand, channel, company: companyId, status })

  return fetch(
    url,
    {
      method: 'POST',
      headers,
      body: JSON.stringify({
        erp_company_id: companyId,
        name: orderName,
        groups
      })
    }
  )
    .then(checkResponseSuccess)
}
