<template>
  <div class="delivery-week-selector" v-if="weeks" data-testid="delivery-week-selector">
    <label :class="labelClass" :for="inputId">{{ $t(DELIVERY_DATE) }}</label>
    <select class="form-control custom-select text-muted" :id="inputId"
      :class="inputClass" :name="name" @change="handleInput">
      <option v-for="week in weeks" :value="week.value" :selected="week.value === value">
        {{ (week.value === 0) ? $t(ASAP) : $t(WEEK, {weekno: week.weekno, year: week.year}) }}
      </option>
    </select>
  </div>
</template>
<script setup>
import { computed, inject } from 'vue'
import { DELIVERY_DATE, WEEK, ASAP } from '@/i18n/constants'

const emit = defineEmits(['update:deliveryWeek'])
const settings = inject('settings', {})

const props = defineProps({
  labelClass: String,
  inputClass: String,
  inputId: String,
  name: String,
  value: String,
  direct: Boolean
})
const weeks = computed(() => {
  if (props.direct === true) {
    return [{ value: 0 }]
  }
  return (settings?.deliveryWeeks) ? settings.deliveryWeeks : []
})

function handleInput ({ target }) {
  emit('update:deliveryWeek', target.value)
}
</script>
