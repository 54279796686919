<template>
  <div class="line-item-groups" data-testid="line-item-groups">
    <LineItemGroup v-for="group in cartStore.groups" :lineItemGroup="group"/>
  </div>
</template>
<script setup>
import { inject } from 'vue'
import LineItemGroup from '@/components/organisms/Cart/LineItemGroup/LineItemGroup.vue'

const cartStore = inject('cartStore')
</script>
