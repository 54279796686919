<template>
  <article class="line-item line-item--totals totals"
           v-if="showPrices"
           data-testid="line-item--totals">
    <div class="totals__line totals__line--total">
      <span class="totals__line-title">{{ $t(SUBTOTAL) }}</span>
      <ProtectedPrice class="totals__amount" :price="price" :data-testid="elementTestId" :shouldBlur="shouldBlur"/>
    </div>
  </article>
</template>
<script setup>
import { inject, ref } from 'vue'
import ProtectedPrice from '@/components/atoms/ProtectedPrice/ProtectedPrice.vue'
import { SUBTOTAL } from '@/i18n/constants'
import { lineItemGroupProps } from '@/helpers/props'

const props = defineProps({
  price: {
    type: Number,
    required: true
  },
  shouldBlur: Boolean,
  lineItemGroup: lineItemGroupProps
})

const settings = inject('settings', {})
const showPrices = ref(settings.showPrices)
const elementTestId = `line-items__subtotal--${props.lineItemGroup.title.toLowerCase()}`

</script>
