<template>
  <div class="line-item__battery-selector" data-testid="line-item__battery-selector">
    <label class="line-item__selectbox-label" :for="inputId">{{ $t(BATTERY) }}</label>
    <select v-if="showDiv === false" class="line-item__selectbox form-control custom-select text-muted" :id="inputId"
      :name="name" @change="changeBattery">
      <option v-if="batteryRequired === false" value="">{{ $t(CHOOSE_A_BATTERY) }}</option>
      <option v-for="battery in batteries" :data-stock-class="battery.stock_status"
        :data-stock-status="battery.stock_status_label" :value="battery.sku"
        :selected="battery.sku === lineItem.batterySku">
        {{ battery.name }}
      </option>
    </select>
    <div v-if="showDiv === true">{{ batteries[0].name }}</div>
    <div>
      <ProtectedPrice class="line-item__battery-price price" :price="batteryPrice" data-testid="price" />
    </div>
  </div>
</template>
<script setup>
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import { inject, reactive, computed } from 'vue'
import { lineItemProps } from '@/helpers/props'
import { BATTERY, CHOOSE_A_BATTERY } from '@/i18n/constants'
import ProtectedPrice from '@/components/atoms/ProtectedPrice/ProtectedPrice.vue'
import { getBatteryPrice } from '@/helpers/lineItem.js'

const cartStore = inject('cartStore')
const settings = inject('settings', {})

const props = defineProps({
  lineItem: lineItemProps,
  inputId: String,
  name: String
})

const batteryPrice = computed(() => getBatteryPrice(props.lineItem, settings.isUseSimulatedPrice))
const batteryRequired = computed(() => !!(props.lineItem?.battery_required))
const showDiv = computed(() => (batteries.length === 1 && batteryRequired.value))

const batteries = reactive(props.lineItem.battery_info.map(battery => ({
  ...battery,
  sku: battery.material_number,
  stock_status: 'TODO',
  stack_status_label: 'TODO'
})))

function changeBattery (event) {
  props.lineItem.batterySku = event.target.value
  cartStore.actions.changeBattery(props.lineItem._id, event.target.value)
    .catch(e => console.error(e))
}
</script>
