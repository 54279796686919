const timers = {}

export function isTimerActive (uid) {
  return !!(timers[uid])
}
/**
 * Create a debounced function caller, you'll need to provide the uid on every call,
 * it will be used to track the debounces on this function.
 *
 * @param {Function} action The action to call after timeout expires
 * @param {number} timeout The timeout to wait before calling action
 * @returns {Function} action wrapped in debounce function
 */
export function debounceWithUID (action, timeout) {
  /**
   * The debounced version of the passed action.
   *
   * @param {*} uid Any unique identifier
   * @param {...*} args Remaining arguments are passed streight to action
   */
  function debouncedAction (uid, ...args) {
    if (timers[uid]) {
      window.clearTimeout(timers[uid])
    }

    timers[uid] = window.setTimeout(
      () => action(...args),
      timeout
    )
  }

  return debouncedAction
}
