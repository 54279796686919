<template>
  <div class="spin-button" data-testid="spin-button">
    <span class="spin-button__decrement spin-button__action"
          @click="decrease"
          data-testid="spin-button__decrease">-</span>
    <input class="spin-button__input"
           type="text"
           :class="inputClass"
           :max="max"
           :min="min"
           :name="inputName"
           :value="quantity"
           @input="onQuantityChange"
           @change="onInputChange"
           data-testid="spin-button__input"/>
    <span class="spin-button__increment spin-button__action"
          @click="increase"
          data-testid="spin-button__increase">+</span>
  </div>
</template>
<script setup>
import { computed } from 'vue'

const emit = defineEmits(['change:quantity'])
const props = defineProps({
  inputClass: String,
  min: {
    type: Number,
    default: 1
  },
  max: {
    type: Number,
    default: Infinity
  },
  inputName: String
})
const quantity = defineModel('quantity', { type: Number, default: 0 })

const isInRange = (newQuantity) => {
  if (!isNaN(props.max) && !isNaN(props.min)) {
    return newQuantity <= props.max && newQuantity >= props.min
  }
  if (!isNaN(props.max)) {
    return newQuantity <= props.max
  }
  return newQuantity >= props.min
}

const isNumeric = value => /^\d+$/.test(value)
const stripNonNumeric = value => value.replace(/\D/g, '')
const stripLastCharacter = value => value.slice(0, -1)
const toQuantity = value => Number(stripNonNumeric(value))
const undoLastInput = (newValue, oldValue) => newValue.length > 1 ? stripLastCharacter(newValue) : oldValue

const decreasable = computed(() => isNaN(quantity.value) || quantity.value > props.min)
const increasable = computed(() => isNaN(quantity.value) || quantity.value < props.max)
const decrease = () => {
  if (decreasable.value) {
    --quantity.value
    emitChangeQuantity()
  }
}
const increase = () => {
  if (increasable.value) {
    ++quantity.value
    emitChangeQuantity()
  }
}
const onQuantityChange = event => {
  if (!isNumeric(event.target.value)) {
    event.preventDefault()
    event.target.value = stripNonNumeric(event.target.value)
    return
  }

  const newQuantity = toQuantity(event.target.value)
  if (quantity.value === newQuantity) {
    event.preventDefault()
    return
  }

  if (!isInRange(newQuantity)) {
    event.preventDefault()
    event.target.value = undoLastInput(event.target.value, quantity.value)
    return
  }

  quantity.value = newQuantity
  emitChangeQuantity()
}

function emitChangeQuantity () {
  emit('change:quantity', quantity.value)
}
</script>
