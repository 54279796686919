<template>
  <div data-testid="line-item-group-selector">
    <LineItemGroupSelectorItem v-for="group in cartStore.groups" :lineItemGroup="group"/>
  </div>
</template>
<script setup>
import { inject } from 'vue'
import LineItemGroupSelectorItem from './Item/Item.vue'

const cartStore = inject('cartStore')
</script>
