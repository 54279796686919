/**
 * A helper function to insert into your promise chain so that you can safely
 * use the (cloned) HTTPResponse. It also adds a `finally` clause to deal with
 * a potential old-skool callback method.
 * Also the `finally` clause will return the original HTTPResponse when the
 * callback is not supplied.
 *
 * @param {Response} httpResponse
 * @param {Function=} callback
 * @returns {Promise<Response>} Resolves to the `Response` put into the method
 */
export async function callbackFallbackWithHTTPResponse (httpResponse, callback) {
  return Promise
    .resolve(httpResponse.clone())
    .finally(() => {
      if (callback) {
        return callback(httpResponse)
      }
      return httpResponse
    })
}
