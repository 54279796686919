<template>
  <div class="is-sold-selector custom-control custom-checkbox" data-testid="is-sold-selector">
    <input
      class="custom-control-input"
      :id="inputId"
      :name="name"
      :checked="value"
      @change="handleInput"
      type="checkbox" />
    <label class="custom-control-label pr-0" :for="inputId">{{ $t(SOLD) }}</label>
  </div>
</template>
<script setup>
import { SOLD } from '@/i18n/constants'

const emit = defineEmits(['update:sold'])
defineProps({
  inputId: String,
  name: String,
  value: {
    type: Boolean,
    required: true
  }
})

function handleInput ({ target }) {
  emit('update:sold', target.checked)
}

</script>
