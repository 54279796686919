<template>
  <a class="line-item__empty-cart link-underline"
     @click.prevent="onClick"
     href="#"
     data-testid="line-item__empty-cart">{{ t(REMOVE_ALL_ITEMS) }}</a>
</template>
<script setup>
import { inject } from 'vue'
import { useI18n } from 'vue-i18n'
import useConfirmDialog from '@/composables/useConfirmDialog'
import { CONFIRM_EMPTY_CART, REMOVE_ALL_ITEMS } from '@/i18n/constants'

const cartStore = inject('cartStore')
const { t } = useI18n({ useScope: 'global' })

async function onClick () {
  const confirmed = await useConfirmDialog(t(CONFIRM_EMPTY_CART))
  if (confirmed) {
    cartStore.emptyCart()
  }
}
</script>
