<template>
  <a class="line-item__action line-item__action--remove button-group__item ponbike-icon--trash-bin --invert-on-hover"
     @click.prevent="onClick"
     :title="$t(REMOVE_ITEM)"
     href="#"
     data-testid="line-item__action--remove"></a>
</template>
<script setup>
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import { inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { lineItemProps, lineItemGroupProps } from '@/helpers/props'
import useConfirmDialog from '@/composables/useConfirmDialog'
import { REMOVE_ITEM } from '@/i18n/constants'

const props = defineProps({
  lineItem: lineItemProps,
  lineItemGroup: lineItemGroupProps
})
const cartStore = inject('cartStore')
const { t } = useI18n({ useScope: 'global' })
const message = 'Are you sure you want to remove this item?'

async function onClick () {
  const confirmed = await useConfirmDialog(t(message))
  if (confirmed) {
    await cartStore.actions.deleteOrderLine(props.lineItem)
  }
}

</script>
