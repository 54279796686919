export default class Cache {
  #storage
  #ttlInMs

  constructor ({ ttl = 300, storage = localStorage } = {}) {
    this.#ttlInMs = ttl * 1000
    this.#storage = storage
  }

  /**
   * Get an object by key from the storage
   *
   * @param {string} key
   * @returns {JSON|null}
   */
  get (key) {
    const value = JSON.parse(this.#storage.getItem(key.toString()))
    return (value?.expiration > Date.now()) ? value.value : null
  }

  /**
   * Set a value to the storage
   *
   * @param {string} key
   * @param {string | object} value
   */
  set (key, value) {
    this.#storage.setItem(key.toString(), JSON.stringify({
      expiration: Date.now() + this.#ttlInMs,
      value
    }))
  }

  /**
   * Delete an object by key from the storage
   *
   * @param {string} key
   */
  delete (key) {
    this.#storage.removeItem(key.toString())
  }

  get size () {
    return this.#storage.length
  }

  get expiration () {
    return this.#ttlInMs
  }
}
