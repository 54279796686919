<template>
  <address class="address-switcher__item"
           :class="{'is-selected': isSelected, 'address-switcher__item--failed': isFailed}"
           @click="cartStore.actions.updateActiveAddresses({newAddress: address})"
           data-testid="address-switcher__item">
      <header class="address-switcher__item-title h4 text-body mb-0">
        {{ company(address).value }}
        <label class="text-parenthesis text-muted mb-0">
          {{ address?.active?.cartSum }}
        </label>
      </header>
      <span class="address-switcher__item-description">
        {{ streetCity(address).value }}
      </span>
      <div v-if="isFailed" class="address-switcher__item--error-text">{{ $t(ORDER_FAILED) }}</div>
  </address>
</template>
<script setup>
import useAddressFormat from '@/composables/useAddressFormat'
import { inject, computed } from 'vue'
import { ORDER_FAILED } from '@/i18n/constants.js'

const cartStore = inject('cartStore')
const { company, streetCity } = useAddressFormat()

const props = defineProps({
  address: {
    type: Object,
    required: true
  }
})

const isFailed = computed(() => (props.address?.active?.status === 'failed'))
const isSelected = computed(() => {
  const { active: activeProp, ...addrProp } = props.address
  const { active: activeSel, ...addrSel } = cartStore.selectedAddress ?? {}
  const statusProp = activeProp?.status ?? 'open'
  const statusSel = activeSel?.status ?? 'open'

  return JSON.stringify(addrProp) === JSON.stringify(addrSel) && statusProp === statusSel
})

</script>
