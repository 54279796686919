/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import { reactive, watch } from 'vue'
import * as cartService from '@/service/cartService'
import { LINE_ITEM_GROUPS } from '@/constants/lineItem'
import magentoSettings from '@/stores/magentoSettings'
import useAddressFormat from '@/composables/useAddressFormat'
import Cache from '@/helpers/cache.js'
import { ACCESSORIES, BIKES, PARTS } from '@/i18n/constants'
import { getAnalyticsLineItem } from '@/helpers/lineItem'
import { safeSumN } from '@/math/safe-sum'

const { erpAddressId } = useAddressFormat()

const cache = new Cache()

const cartStatus = Object.freeze({
  OPEN: 'open',
  SUBMITTED: 'submitted',
  PROCESSING: 'processing',
  CONFIRMED: 'confirmed',
  FAILED: 'failed'
})

function getCartKey (address, brand, channel, company, status = cartStatus.OPEN) {
  return [address, brand, channel, company, status, 'cart'].join('_')
}

function getActiveCartKey (brand, channel) {
  return [brand, channel, 'active', 'carts'].join('_')
}

function deDuplicateOrderLines (orderLines) {
  return orderLines.reduce((acc, cur) => {
    const existing = acc.find((line) => line._id === cur._id)
    if (existing) {
      existing.subLine = cur
      existing.splitQuantity = existing.quantity
      existing.quantity += cur.quantity
    } else {
      acc.push(cur)
    }
    return acc
  }, [])
}

function storeLines (content) {
  let errorLines = 0
  Object.values(LINE_ITEM_GROUPS).forEach((lineItemGroupKey) => {
    if (!content[lineItemGroupKey]) {
      return
    }
    store.lines[lineItemGroupKey] = content[lineItemGroupKey]
    store.lines[lineItemGroupKey].lines = deDuplicateOrderLines(store.lines[lineItemGroupKey].lines)

    errorLines += content[lineItemGroupKey]?.lines
      .reduce((acc, cur) => (cur.sku === store.errorSku) ? acc + 1 : acc, 0)
  })
  if (store.errorSku !== null && errorLines === 0) {
    store.errorSku = null
  }
}

function storeLineChanges (orderLinePartial, key) {
  const { _id: orderLineID, ...changedAttributes } = orderLinePartial
  const cachedContent = cache.get(key)
  Object.values(LINE_ITEM_GROUPS).forEach(lineItemGroupKey => {
    const storedOrderline = store.lines[lineItemGroupKey]?.lines.find(
      (orderLine) => orderLineID === orderLine._id
    )
    // order-line is part of this group
    if (storedOrderline) {
      // update the order-line
      Object.keys(changedAttributes).forEach((attribute) => {
        storedOrderline[attribute] = changedAttributes[attribute]
      })
      // update the cache
      if (cachedContent) {
        cachedContent[lineItemGroupKey] = store.lines[lineItemGroupKey]
      }
    }
  })
  if (cachedContent) {
    cache.set(key, cachedContent)
  }
  store.updateNeeded = true
}

function resetStore (result = null) {
  store.count = -1
  store.totalQuantity = -1
  store.lastResult = result
  store.isApiDown = true
}

function setStorePeripherals (content) {
  store.cartId = content.cartId
  store.count = content.count
  store.totalQuantity = content.totalQuantity
  store.totals = content.totals
  store.simulatedTotals = content.simulatedTotals

  magentoSettings.hasSimulatedPrices = content.hasOwnProperty('simulatedTotals')
}

async function parseResult (result, key, updateNeeded = false) {
  if (result.status > 399) {
    resetStore(result)
  } else {
    store.isApiDown = false
    const content = (typeof result.json === 'function') ? await result.json() : result
    // In case of partial update
    if (content?.filter) {
      storeLineChanges(content.update, key)
      return
    }
    // not a cached response, so cache it
    if (key && typeof result.json === 'function') {
      cache.set(key, content)
      if (updateNeeded) {
        store.updateNeeded = true
      }
    }
    storeLines(content)

    setStorePeripherals(content)
    store.lastResult = result
  }
}

const store = reactive({
  errorSku: null,
  updateNeeded: false,
  isLoading: true,
  count: 0,
  totalQuantity: 0,
  lastResult: null,
  isModalOpen: false,
  isSuccessModalOpen: false,
  isApiDown: false,
  selectedAddress: null,
  addresses: [],
  totals: {},
  simulatedTotals: {},
  lines: {
    [LINE_ITEM_GROUPS.bikes]: { count: 0, lines: [] },
    [LINE_ITEM_GROUPS.parts]: { count: 0, lines: [] },
    [LINE_ITEM_GROUPS.accessories]: { count: 0, lines: [] }
  },
  groups: [
    {
      checked: true,
      title: BIKES,
      type: LINE_ITEM_GROUPS.bikes
    },
    {
      checked: true,
      title: PARTS,
      type: LINE_ITEM_GROUPS.parts
    },
    {
      checked: true,
      title: ACCESSORIES,
      type: LINE_ITEM_GROUPS.accessories
    }
  ],
  actions: {
    wakeup,
    fetchCartState,
    fireEmptyCartEvent,
    fireOpenCartEvent,
    fireRemoveLineItemEvent,
    fireViewCartEvent,
    fireBeginCheckoutEvent,
    firePurchaseEvent,
    updateOrderSimulate,
    updateActiveAddresses,
    changeQuantity,
    changeSold,
    changeNote,
    changeBattery,
    changeDeliveryWeek,
    deleteOrderLine,
    addOrderLines,
    submitCart,
    orderStatus,
    getServiceStatus
  },
  async emptyCart () {
    store.actions.fireEmptyCartEvent()
    const { brand, channel, companyId } = magentoSettings
    const status = getCartStatusFromAddress(store.selectedAddress)
    await cartService.deleteCart(erpAddressId(store.selectedAddress).value, brand, channel, status)
    cache.delete(getCartKey(erpAddressId(store.selectedAddress).value, brand, channel, companyId))
    cache.delete(getActiveCartKey(brand, channel))
    store.selectedAddress = null
    store.totalQuantity = 0
    updateActiveAddresses({})
  },
  removeLineItem (item, group) {
    const index = store.lines[group].lines.indexOf(item)
    store.lines[group].lines.splice(index, 1)
  },
  toggleCartModal (override) {
    store.isModalOpen = override ?? (!store.isModalOpen)
    store.triggerViewCartEvent()
  },
  toggleSuccessModal () {
    store.isSuccessModalOpen = !store.isSuccessModalOpen
  },
  triggerViewCartEvent () {
    const isAfterPageLoad = store.isModalOpen && store.lastResult !== null
    if (isAfterPageLoad) {
      store.actions.fireViewCartEvent()
    }

    const isOnPageLoad = store.isModalOpen && store.isLoading && store.lastResult === null
    if (isOnPageLoad) {
      watch(() => store.isLoading, (isLoading) => {
        if (!isLoading) store.actions.fireViewCartEvent()
      })
    }
  }
})

/**
 * No longer using a delay, keeping this method as we should stop manipulating
 * the store directly.
 */
const setLoading = ((localStore) => function (isLoading) {
  localStore.isLoading = isLoading
})(store)

async function wakeup () {
  await updateActiveAddresses({ fresh: false })
}

async function getServiceStatus () {
  try {
    const response = await cartService.getStatus()
    const apiVersion = response.headers.get('X-Version')
    const poweredBy = response.headers.get('X-Powered-By')
    const result = await response.json()
    store.isApiDown = !result.status
    return { ...result, poweredBy, apiVersion }
  } catch (error) {
    store.isApiDown = true
    return { status: false, message: 'connection error' }
  }
}

function getCartStatusFromAddress (address) {
  return address?.active?.status ?? cartStatus.OPEN
}

async function fetchCartState ({ fresh = true } = {}) {
  const { brand, channel, companyId } = magentoSettings
  setLoading(true)
  try {
    const address = erpAddressId(store.selectedAddress).value
    const status = getCartStatusFromAddress(store.selectedAddress)
    const key = getCartKey(address, brand, channel, companyId, status)

    if (fresh) {
      cache.delete(key)
    }

    let cartState = cache.get(key)
    if (cartState === null) {
      const result = await cartService.fetchCart(
        address, brand, channel, companyId, status
      )
      cartState = result
    }
    await parseResult(cartState, key, true)
  } catch (failedResult) {
    // REASON: We probably need to know why this happened
    // eslint-disable-next-line no-console
    console?.trace(failedResult)
    store.isApiDown = true
    await parseResult({
      status: 418
    })
  }

  setLoading(false)
}

async function getActiveCarts ({ brand, channel, fresh = true }) {
  const key = getActiveCartKey(brand, channel)
  let cached = cache.get(key)
  if (fresh || cached === null) {
    const result = await cartService.fetchActiveCarts(brand, channel)
    cached = await result.json()
    cache.set(key, cached)
    store.updateNeeded = true
  }
  return cached
}

async function updateActiveAddresses ({ newAddress, fresh = true }) {
  const previousAddress = store.selectedAddress
  if (newAddress) {
    store.selectedAddress = newAddress
  }

  const { brand, channel, addresses, defaultAddress } = magentoSettings
  const activeCarts = await getActiveCarts({ brand, channel, fresh })

  // Merge cart-statuses with magento addresses
  store.addresses = activeCarts
    .map(cart => {
      const activeAddress = addresses.find(address => erpAddressId(address).value === cart.address)
      if (!activeAddress) return null
      return { ...activeAddress, active: cart }
    })
    .filter(a => a) // filter null values

  const defaultActiveAddress = store.addresses[0]

  if (!activeCarts.map(ac => ac.address).includes(erpAddressId(store.selectedAddress).value)) {
    store.selectedAddress = null
  }

  store.selectedAddress = store.selectedAddress ?? defaultActiveAddress ?? defaultAddress

  // Close modal when no active carts are available for current user / dealer combo
  if (store.addresses.length === 0 && store.isModalOpen) {
    store.toggleCartModal()
    return
  }

  if (!previousAddress || previousAddress !== store.selectedAddress || fresh === false) {
    // Not awaiting deliberately (allow UI to render while loading in the background)
    store.actions.fetchCartState({ fresh })
  }
}

async function updateOrderSimulate () {
  try {
    const { brand, channel, companyId } = magentoSettings
    const address = erpAddressId(store.selectedAddress).value
    const status = getCartStatusFromAddress(store.selectedAddress)
    const result = await cartService.fetchOrderSimulation({
      address,
      brand,
      channel,
      companyId,
      status,
      groups: store.groups.filter((group) => group.checked).map((group) => group.type)
    })
    const key = getCartKey(address, brand, channel, companyId)
    await parseResult(result, key, true)
    if (store.errorSku) store.errorSku = null
  } catch (errorResponse) {
    if (errorResponse?.constructor === Response) {
      const message = await errorResponse.json()
      store.errorSku = message.sku
    }
  }
}

/**
 * Commonly we need to parse results from cartman service AND update the
 * active addresses. This method facilitates that and does so in parallel
 * so that we also get a little bump in performance.
 *
 * @param {object} opts
 * @param {*} opts.result A basket returned from the cartman service
 * @param {string} [opts.addressId] The latest modified address
 * @param {string} [opts.key]
 */
async function parseResultAndUpdate ({ result, addressId, key }) {
  const { addresses } = magentoSettings
  await Promise.all([
    parseResult(result, key),
    updateActiveAddresses({
      newAddress: addressId ? addresses.find((address) => erpAddressId(address).value === addressId) : undefined
    })
  ])
}

async function submitCart () {
  const { brand, channel, companyId } = magentoSettings
  const status = getCartStatusFromAddress(store.selectedAddress)
  const groupsToOrder = Object.fromEntries(store.groups.map(grp => [grp.type, grp.checked]))
  /** @type {Response} */
  const resp = await cartService.submitCart(
    erpAddressId(store.selectedAddress).value,
    brand,
    channel,
    status,
    groupsToOrder
  )
  cache.delete(getCartKey(erpAddressId(store.selectedAddress).value, brand, channel, companyId, status))
  cache.delete(getActiveCartKey(brand, channel))
  // Fire and forget address update (should resolve in the store by itsself)
  updateActiveAddresses({ fresh: true }).catch((e) => console.warn('Failed to update addresses, better luck next time', e))

  if (resp.status === 202) {
    const location = resp.headers.get('location')
    const result = await resp.json()
    result.location = location
    return result
  }
  return null
}

async function orderStatus (url) {
  const resp = await cartService.orderStatus(url)
  if (resp.status === 202) {
    const retry = resp.headers.get('retry-after')
    const result = await resp.json()
    result.retry = retry ?? Number(false)
    return result
  }
  return null
}

/**
 * Change a single property of an order-line.
 *
 * @param {string} id The id of the order-line
 * @param {JSON} updatedProp A simple value object with the key-value pair of the property
 */
async function changeProperty (id, updatedProp) {
  const { brand, channel, companyId } = magentoSettings
  const address = erpAddressId(store.selectedAddress).value
  const result = await cartService.updateProperty(
    address, brand, channel, companyId, id, updatedProp
  )

  const key = getCartKey(address, brand, channel, companyId)
  await parseResultAndUpdate({ result, key })
}

/**
 * Change the quantity property of an order-line.
 *
 * @param {string} id The id of the order-line
 * @param {number} quantity The new quantity value of the order-line
 * @returns {Promise<void>}
 */
function changeQuantity (id, quantity) {
  return changeProperty(id, { quantity })
}

/**
 * Change the isBikeSold property of an order-line.
 *
 * @param {string} id The id of the order-line
 * @param {boolean} sold The new `isBikeSold` value of the order-line
 * @returns {Promise<void>}
 */
function changeSold (id, sold) {
  return changeProperty(id, {
    isBikeSold: sold
  })
}

/**
 * Change the batterySku property of an order-line.
 *
 * @param {string} id The id of the order-line
 * @param {string} battery The new `batterySku` value of the order-line
 * @returns {Promise<void>}
 */
function changeBattery (id, battery) {
  return changeProperty(id, {
    batterySku: battery
  })
}

/**
 * Change the delivery week property of an order-line.
 *
 * @param {string} id The id of the order-line
 * @param {string} week The new `deliveryWeek` value of the order-line
 * @returns {Promise<void>}
 */
function changeDeliveryWeek (id, week) {
  return changeProperty(id, {
    deliveryWeek: week
  })
}

/**
 * Change the note property of an order-line.
 *
 * @param {string} id The id of the order-line
 * @param {string} note The new note value of the order-line
 * @returns {Promise<void>}
 */
function changeNote (id, note) {
  return changeProperty(id, { note })
}

async function addOrderLines (orderLines) {
  const totalQuantityBefore = store.totalQuantity
  // new orderlines will always end up on 'open' carts
  const result = await cartService.postProductLines(orderLines, cartStatus.OPEN)
  const key = getCartKey(
    orderLines[0].erpAddressId,
    orderLines[0].brand,
    orderLines[0].channel,
    orderLines[0].erpCompanyId
  )
  await parseResultAndUpdate({ key, result: await result.clone(), addressId: orderLines[0].erpAddressId })
  if (totalQuantityBefore === 0) {
    store.actions.fireOpenCartEvent()
  }
  return result
}

async function deleteOrderLine (orderLine) {
  await deleteOrderLineById(orderLine._id)
  store.actions.fireRemoveLineItemEvent(orderLine)
}

async function deleteOrderLineById (id) {
  const { brand, channel, companyId } = magentoSettings
  const address = erpAddressId(store.selectedAddress).value
  const status = getCartStatusFromAddress(store.selectedAddress)
  const result = await cartService.deleteOrderLine(
    address, brand, channel, companyId, status, id
  )
  const key = getCartKey(address, brand, channel, companyId)
  await parseResultAndUpdate({ result, key })
}

function fireEmptyCartEvent () {
  magentoSettings.fireGtmEvent('empty_cartman', { cartId: store.cartId })
}

function fireOpenCartEvent () {
  magentoSettings.fireGtmEvent('open_cartman', { cartId: store.cartId })
}

function fireRemoveLineItemEvent (lineItem) {
  magentoSettings.fireGtmEvent('remove_from_cartman', { cartId: store.cartId, lineItem })
}

function fireViewCartEvent () {
  magentoSettings.fireGtmEvent('view_cartman', { cartId: store.cartId, lineItems: store.lines })
}

function fireBeginCheckoutEvent () {
  const dataLayerMessage = {
    event: 'begin_checkout',
    ecommerce: {
      items: [].concat(
        ...Object.values(store.lines).map((group) => group.lines)
      ).map(getAnalyticsLineItem)
    }
  }
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(dataLayerMessage)
  const purchaseMessage = {
    ecommerce: {
      currency: magentoSettings.currency,
      value: safeSumN(100)(...store.groups.map(
        (group) => group.checked ? store.lines[group.type].totals.price : 0
      )),
      tax: 0,
      shipping: store.simulatedTotals?.shipping_costs ?? 0,
      affiliation: magentoSettings.brand,
      // transaction_id: 'p115-20202000',
      // coupon: 'free_back_rub',
      items: dataLayerMessage.ecommerce.items
    }
  }
  window.sessionStorage.setItem('GA_PURCHASE_CACHE', JSON.stringify(purchaseMessage))
}

function firePurchaseEvent (transactionId) {
  const purchaseMessage = JSON.parse(window.sessionStorage.getItem('GA_PURCHASE_CACHE') ?? '{}')
  if (!purchaseMessage.ecommerce) {
    // REASON: We want to get this error logged
    // eslint-disable-next-line no-console
    console.error('No purchase event cached, cannot track this event')
    return
  }

  window.sessionStorage.removeItem('GA_PURCHASE_CACHE')
  const dataLayerMessage = {
    event: 'purchase',
    ecommerce: {
      ...purchaseMessage.ecommerce,
      transaction_id: transactionId
    }
  }
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(dataLayerMessage)
}

export default store
export {
  store,
  updateActiveAddresses,
  deleteOrderLine,
  cartStatus
}
