<template>
  <div class="totals"
       v-if="showPrices"
       data-testid="cart-totals">
    <div class="totals__line totals__line--subtotal">
      <div>{{ $t(SUBTOTAL) }}</div>
      <ProtectedPrice class="totals__amount" :price="subTotal"/>
    </div>
    <div class="totals__line totals__line--discount" v-if="showAddtionalCosts && discountTotal">
      <div>{{ $t(DISCOUNT) }}</div>
      <ProtectedPrice class="totals__amount" :price="discountTotal"/>
    </div>
    <div class="totals__line totals__line--surcharge" v-if="showAddtionalCosts">
      <div>{{ $t(SURCHARGE) }}</div>
      <ProtectedPrice class="totals__amount" :price="surCharge"/>
    </div>
    <div class="totals__line totals__line--delivery" v-if="showAddtionalCosts">
      <div>{{ $t(DELIVERY_COSTS) }}</div>
      <ProtectedPrice class="totals__amount" :price="deliveryCost"/>
    </div>
    <div class="totals__line totals__line--total">
      <div>{{ $t(ORDER_TOTAL) }}</div>
      <ProtectedPrice class="totals__amount" :price="orderTotal"/>
    </div>
  </div>
</template>
<script setup>
import { computed, inject, ref } from 'vue'
import ProtectedPrice from '@/components/atoms/ProtectedPrice/ProtectedPrice.vue'
import { DELIVERY_COSTS, DISCOUNT, ORDER_TOTAL, SUBTOTAL, SURCHARGE } from '@/i18n/constants'
import { parse } from '@/helpers/lineItem.js'
import { safeSumN } from '@/math/safe-sum'

const cartStore = inject('cartStore', {})
const settings = inject('settings', {})
const showPrices = ref(settings.showPrices)
const showAddtionalCosts = ref(settings.isUseSimulatedPrice)

function pickYourPrice (rrp, pp) {
  return parse((settings.isUseSimulatedPrice ? rrp : pp) ?? 0)
}

function calculateSubTotal () {
  if (cartStore.simulatedTotals && settings.isUseSimulatedPrice) {
    return cartStore.simulatedTotals.calculated_sub_total
  }
  return safeSumN(100)(...cartStore.groups.map(
    (group) => group.checked ? cartStore.lines[group.type].totals.price : 0
  ))
}

const subTotal = computed(calculateSubTotal)
const discountTotal = computed(() => pickYourPrice(cartStore.simulatedTotals?.discount_total, 0))
const surCharge = computed(() => pickYourPrice(cartStore.simulatedTotals?.surcharge_total, 0))
const deliveryCost = computed(() => pickYourPrice(cartStore.simulatedTotals?.shipping_costs, 0))
const orderTotal = computed(() => pickYourPrice(cartStore.simulatedTotals?.calculated_grand_total, subTotal.value))

</script>
