/* eslint-disable n/no-unsupported-features/node-builtins */

/**
 * this is a local copy from @ponbike/url-generator!
 *
 * to bypass issues with polyfills for URL
 */
export default class UrlGenerator {
  constructor () {
    this.url = null
    this.path = {}
    this.searchParams = {}
  }

  setUrl (url, base) {
    if (!url || url.constructor !== String) {
      throw new Error('url isnt a valid string')
    }
    this.url = (base) ? new URL(url, base).href : new URL(url).href
  }

  setSearchParams (searchParams) {
    if (!searchParams) {
      return
    }
    if (searchParams.constructor !== Object) {
      throw new Error('searchParams isnt a valid object')
    }
    this.searchParams = searchParams
  }

  setPath (path) {
    if (!path) {
      return
    }
    if (path.constructor !== Object) {
      throw new Error('path isnt a valid object')
    }
    this.path = path
  }

  urlReducer (accumulator, [key, value]) {
    const lastChar = accumulator.substr(-1)
    const seperator = lastChar === '/' ? '' : '/'

    if (!value) {
      return accumulator + seperator + key
    }

    return `${accumulator + seperator + key}/${value}`
  }

  toString () {
    const completeUrl = new URL(Object.entries(this.path).reduce(this.urlReducer, this.url))
    Object.entries(this.searchParams).forEach(([searchParam, searchValue]) => {
      if (searchValue) {
        completeUrl.searchParams.append(searchParam, searchValue)
      }
    })

    return completeUrl.href
  }

  /**
   * Generate an url from the url, path and search params
   *
   * @param {object} param
   * @param {string} param.url
   * @param {object} param.path
   * @param {object} param.searchParams
   * @param {string|null} param.base
   * @returns {string}
   */
  static create ({ url, path, searchParams, base = null }) {
    const newUrl = new UrlGenerator()
    newUrl.setUrl(url, base)
    newUrl.setPath(path)
    newUrl.setSearchParams(searchParams)

    return newUrl.toString()
  }
}
