<template>
  <div :class="['pon-accordion', wrapperClass]" data-testid="pon-accordion">
    <div :class="['pon-accordion__header', headerClass, {
           'pon-accordion__header--open': showContent,
           'pon-accordion__header--closed': !showContent,
           hover: !showContent
         }]"
         @click="isClickTrigger(headerRef) && toggle($event)"
         ref="headerRef">
      <div class="pon-accordion__toggle pon-accordion__header-wrapper"
           @click="isClickTrigger(toggleRef) && toggle($event)"
           ref="toggleRef">
        <slot name="header"></slot>
      </div>
      <transition name="accordion"
                  v-on:before-enter="beforeEnter" v-on:enter="enter"
                  v-on:before-leave="beforeLeave" v-on:leave="leave"
                  ref="accordion">
        <div :class="['pon-accordion__body', bodyClass]" v-show="showContent">
            <slot name="accordionContent"></slot>
        </div>
      </transition>
    </div>
  </div>
</template>
<script setup>
import { onBeforeMount, ref } from 'vue'

const emit = defineEmits(['change'])

const props = defineProps({
  clickTrigger: {
    type: String,
    default: 'pon-accordion__header'
  },
  isOpen: {
    type: Boolean,
    default: false
  },
  wrapperClass: {
    type: String,
    default: 'pon-accordion'
  },
  bodyClass: String,
  headerClass: String
})

onBeforeMount(() => props.isOpen && toggle())

const showContent = ref(false)
const headerRef = ref(null)
const toggleRef = ref(null)
const isClickTrigger = (element) => element.classList.contains(props.clickTrigger)
const isOpen = () => showContent.value
const toggle = (event) => {
  showContent.value = !showContent.value
  emit('change', showContent.value)
}
const open = () => { showContent.value = true }
const close = () => { showContent.value = false }
const beforeEnter = (element) => collapse(element)
const beforeLeave = (element) => expand(element)
const enter = (element) => expand(element)
const leave = (element) => collapse(element)
const collapse = (element) => { element.style.height = '0' }
const expand = (element) => {
  element.style.height = `${element.scrollHeight}px`
  element.addEventListener('transitionend', function handleExpand () {
    element.removeEventListener('transitionend', handleExpand)
    element.style.height = null
  })
}

defineExpose({
  showContent,
  isOpen,
  toggle,
  open,
  close
})
</script>
