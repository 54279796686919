<template>
  <article
    class="line-item"
    data-test-id="line-item"
    :class="erroneousLineItem ? 'line-item--error' : ''"
  >
    <div class="wrapper line-item--product">
      <StockIndicator :lineItem="lineItem" />
      <div class="line-item__column line-item__column--first">
        <LineItemName :lineItem="lineItem" />
        <div class="line-item__media">
          <div class="line-item__media-labels" :class="{'line-item__media-labels--with-image': isBike(lineItem)}">
            <SkuBadge class="line-item__sku" :sku="lineItem.sku" :productGroup="lineItem.product_group" />
            <DiscountLabelBadge class="line-item__sku" :label="discountLabel" />
          </div>
          <LineItemImage :lineItem="lineItem" v-if="isBike(lineItem)" />
        </div>
      </div>
      <div v-if="showSubline" class="line-item__column line-item__column--quantity">
        <span class="line-item__no-button--quantity">{{ lineItem.splitQuantity }} x</span>
        <span class="line-item__no-button--quantity">{{ lineItem.subLine.quantity }} x</span>
      </div>
      <div
        class="line-item__column line-item__column--attributes"
        :class="{ 'line-item__column--single-price': !showSubline }"
      >
        <LineItemPrice v-if="!showSubline" :lineItem="lineItem" :shouldBlur="shouldBlur"/>
        <LineItemPrice v-if="showSubline" class="line-item__subprice" :lineItem="lineItem" :shouldBlur="shouldBlur"/>
        <LineItemPrice v-if="showSubline"
          class="line-item__subprice" :lineItem="lineItem.subLine" :shouldBlur="shouldBlur"/>
        <ProductAttributes class="line-item__product-attributes" v-if="isBike(lineItem)" :lineItem="lineItem"
          :productAttributes="getProductAttributes(lineItem)" />
      </div>
      <div class="line-item__column line-item__column--details">
        <div class="line-item__options">
          <DeliveryWeekSelector class="line-item__selectbox-container line-item__selectbox-container--delivery-date"
            labelClass="line-item__selectbox-label" inputClass="line-item__selectbox"
            :inputId="`selectDeliveryDate${lineItem.id}`" :value="lineItem.deliveryWeek" :direct="hasDirectDelivery"
            @update:delivery-week="updateDeliveryWeek" />
          <div class="line-item__spin-button-container">
            <LineItemQuantity :lineItem="lineItem" />
          </div>
        </div>
        <BatterySelector class="line-item__selectbox-container line-item__selectbox-container--battery"
          v-if="isBike(lineItem) && lineItem.battery_required" :lineItem="lineItem"
          :inputId="`selectBattery${lineItem.id}`" data-testid="line-item__selectbox-container--battery" />
      </div>
      <div class="line-item__column line-item__column--actions">
        <LineItemActions :lineItem="lineItem" :lineItemGroup="lineItemGroup" />
        <LineItemIcons :lineItem="lineItem" :showRoomMode="settings.isShowroomModeEnabled" />
        <div class="line-item__checkboxes" v-if="isBike(lineItem)" data-testid="line-item__checkboxes">
          <BikeIsSoldSelector v-if="settings?.isSoldEnabled === true" class="line-item__checkbox"
            :value="lineItem.isBikeSold" :inputId="`markBikeAsSold${lineItem._id}`" @update:sold="updateSold" />
        </div>
      </div>
    </div>
    <div class="line-item__error-description" v-if="erroneousLineItem">{{ $t(ERRONEOUS_LINE) }}</div>
  </article>
</template>
<script setup>
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import { inject, computed } from 'vue'
import { lineItemProps, lineItemGroupProps } from '@/helpers/props.js'
import { getProductAttributes, isBike } from '@/helpers/lineItem.js'
import BikeIsSoldSelector from '@/components/atoms/BikeIsSoldSelector/BikeIsSoldSelector.vue'
import DeliveryWeekSelector from '@/components/atoms/DeliveryWeekSelector/DeliveryWeekSelector.vue'
import ProductAttributes from '@/components/atoms/ProductAttributes/ProductAttributes.vue'
import SkuBadge from '@/components/atoms/SkuBadge/SkuBadge.vue'
import DiscountLabelBadge from '@/components/atoms/SkuBadge/DiscountLabelBadge.vue'
import BatterySelector from '@/components/organisms/Cart/LineItem/BatterySelector/BatterySelector.vue'
import LineItemActions from '@/components/organisms/Cart/LineItem/Actions/Actions.vue'
import LineItemIcons from '@/components/organisms/Cart/LineItem/Icons/Icons.vue'
import LineItemImage from '@/components/organisms/Cart/LineItem/Image/Image.vue'
import LineItemQuantity from '@/components/organisms/Cart/LineItem/Quantity/Quantity.vue'
import LineItemName from '@/components/organisms/Cart/LineItem/Name/Name.vue'
import LineItemPrice from '@/components/organisms/Cart/LineItem/Price/Price.vue'
import StockIndicator from '@/components/organisms/Cart/LineItem/StockIndicator/StockIndicator.vue'
import { ERRONEOUS_LINE } from '@/i18n/constants'

const cartStore = inject('cartStore')
const settings = inject('settings', {})

const props = defineProps({
  lineItem: lineItemProps,
  lineItemGroup: lineItemGroupProps,
  shouldBlur: Boolean
})

const erroneousLineItem = computed(() => props.lineItem?.sku === cartStore.errorSku)
const hasDirectDelivery = computed(() => !!props.lineItem?.direct_delivery)
const showSubline = computed(() => !!props.lineItem?.subLine && settings.isUseSimulatedPrice)
const discountLabel = computed(() => props.lineItem.subLine?.discountLabel ?? props.lineItem.discountLabel)

function updateSold (value) {
  cartStore.actions.changeSold(props.lineItem._id, value)
    .catch(e => console.error(e))
}

function updateDeliveryWeek (value) {
  cartStore.actions.changeDeliveryWeek(props.lineItem._id, value)
    .catch(e => console.error(e))
}
</script>
