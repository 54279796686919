<template>
  <SpinButton class="line-item__spin-button--quantity" inputClass="line-item__quantity"
    v-model:quantity="lineItem.quantity" data-testid="line-item__spin-button--quantity"
    @change:quantity="updateQuantity" :max="maxQuantity" />
</template>
<script setup>
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import { inject, computed } from 'vue'
import { lineItemProps } from '@/helpers/props'
import SpinButton from '@/components/atoms/SpinButton/SpinButton.vue'
import { debounceWithUID } from '@/helpers/debounce'

const cartStore = inject('cartStore')
const props = defineProps({
  lineItem: lineItemProps
})

const maxQuantity = computed(() => (props.lineItem?.max_sale_qty) ? props.lineItem?.max_sale_qty : Infinity)

const debouncedQuantity = debounceWithUID(
  async (uid, value) => cartStore.actions.changeQuantity(uid, value),
  1000
)

function updateQuantity (value) {
  const uid = props.lineItem._id
  debouncedQuantity(uid, uid, props.lineItem.quantity)
}
</script>
