export const ACCESSORIES = 'Accessories'
export const ADD_A_MESSAGE = 'Add a message...'
export const ADD_NOTE = 'Add note'
export const ADDRESS = 'Address'
export const AVAILABLE = 'Available'
export const BATTERY = 'Battery'
export const BIKE = 'Bike'
export const BIKES = 'Bikes'
export const CART = 'Cart'
export const CHECK_AVAILABILITY = 'Check availability'
export const CHECK_PRICE_AVAILABILITY = 'Check price and availability'
export const CHOOSE_A_BATTERY = 'Choose a battery'
export const CONFIRM_EMPTY_CART = 'Are you sure you want to empty your cart?'
export const CONFIRM_EMPTY_ITEM = 'Are you sure you want to remove this item?'
export const DELIVERY_COSTS = 'Delivery costs'
export const DISCOUNT = 'Discount'
export const EMPTY_CART = 'You have no items in your shopping carts.'
export const FINALIZE = 'Finalize'
export const IN_PRODUCTION = 'In production'
export const PRODUCTION_WEEK = 'Production week'
export const PRODUCTION_MONTH = 'Production month'
export const NOT_AVAILABLE = 'Not Available'
export const NOTE_FOR = 'Note for'
export const OK = 'OK'
export const ORDER = 'Order'
export const ORDER_1 = 'Order %1'
export const ORDER_TOTAL = 'Order total'
export const PARTS = 'Parts'
export const PLACE_ORDER = 'Order'
export const REMOVE_ALL_ITEMS = 'Remove all items'
export const REMOVE_ITEM = 'Remove item'
export const SOLD = 'Sold'
export const SUBTOTAL = 'Subtotal'
export const SURCHARGE = 'Surcharge'
export const TOTAL = 'Total'
export const VIEW_PRODUCT = 'View product'
export const STATUS = 'Status'
export const STATUS_OPEN = 'Open'
export const STATUS_SUBMITTED = 'Submitted'
export const STATUS_PROCESSING = 'Processing'
export const STATUS_CONFIRMED = 'Confirmed'
export const STATUS_FAILED = 'Failed'
export const STATUS_ERRONEUS = 'Error'
export const ORDER_NUMBERS = 'Order number(s)'
export const ORDER_NUMBERS_WAITING = 'Waiting for order number(s)'
export const TITLE_NONE = 'Nothing here'
export const TITLE_SUBMITTED = "You've successfully submitted your cart!"
export const TITLE_SUBMITTED_MESSAGE = 'Please wait while we process your order.'
export const TITLE_PROCESSING = 'Your order is processing!'
export const TITLE_PROCESSING_MESSAGE = 'Please wait while we process your order.'
export const TITLE_CONFIRMED = 'Your order is confirmed!'
export const TITLE_CONFIRMED_MESSAGE = 'Your order will soon be visible in your backorder.'
export const TITLE_FAILED = 'Your order has failed'
export const TITLE_FAILED_MESSAGE = 'Please go back to your cart to see what caused the failure.'
export const TITLE_ERRONEUS = 'Something went wrong'
export const TITLE_ERRONEUS_MESSAGE = 'Please contact us via the Customer Service Center or your Account Manager.'
export const TITLE_TOO_LONG = 'Sorry...'
export const TITLE_TOO_LONG_MESSAGE = 'Your order is queued but processing takes longer than expected. Your order should be available shortly in your backorder.'
export const SUCCESS_CLOSE = 'To home page'
export const DELIVERY_DATE = 'Delivery date'
export const WEEK = 'Week: {weekno}'
export const ASAP = 'A.S.A.P.'
export const SKU_ERROR = 'The following item can\'t be processed: {sku}'
export const ERRONEOUS_LINE = 'Error: please remove this order line.'
export const ORDER_FAILED = 'Order failed, please use the simulate to find out what\'s wrong'
export const FAILED_ORDER_PRESENT = 'Please solve the failed cart first before ordering.'
export const FAILED_CART = 'This cart has failed, click \'Check price and availability\' to see what is wrong.'
export const PRICE_AVAILABILITY_NOT_AVAILABLE = 'Due to an outage, it is unfortunately not possible to retrieve current data. It is possible to order.'
export const TO_CART = 'To cart'
