<template>
    <Price
        :class="[{
            'cartman__price--unknown': (settings.isUseSimulatedPrice && !settings.hasSimulatedPrices) || shouldBlur
        }]"
        :locale="settings?.locale?.replace('_', '-')"
        :currency="settings.currency"
        v-if="showPrices"
        data-testid="price"
    />
</template>
<script setup>
/** Wrap the Price component and introduce a potential 'masker' class */
import { Price } from '@ponbike/vue-components'
import { inject, ref } from 'vue'

const settings = inject('settings', {})
const showPrices = ref(settings.showPrices)

defineProps({
  shouldBlur: Boolean
})

</script>
