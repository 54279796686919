<template>
  <div class="line-item__column--stock-indicator stock-indicator-y"
    :data-stock-status="stockStatusId"
    :data-type="lineItem?.product_group"
    :title="stockStatusTitle"
    data-testid="line-item__column--stock-indicator">
  </div>
</template>
<script setup>
import { lineItemProps } from '@/helpers/props'
import { getStockStatusId, getStockStatusLabel } from '@/helpers/lineItem'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })
const props = defineProps({
  lineItem: lineItemProps
})

const stockStatusId = getStockStatusId(props.lineItem)
const stockStatusLabel = getStockStatusLabel(props.lineItem)
const stockStatusLabelType = stockStatusLabel.constructor !== String

const stockStatusTitle = (stockStatusLabelType) ? `${t(stockStatusLabel.text)}: ${stockStatusLabel.indication}` : t(stockStatusLabel)
</script>
