<template>
  <Accordion class="line-item-group__accordion" v-if="hasLines" :isOpen="lineItemGroup.checked"
    clickTrigger="pon-accordion__toggle" bodyClass="line-item-group__accordion-body" ref="accordion"
    data-testid="line-item-group__accordion" @change="handleChange">
    <template #header>
      <h3 class="line-item-group__title">
        <span class="line-item-group__toggle-icon"></span>
        {{ $t(lineItemGroup.title) }}
      </h3>
    </template>
    <template #accordionContent>
      <main class="line-item-group">
        <LineItem
          v-for="lineItem in cartStore.lines[props.lineItemGroup.type].lines"
          :key="lineItem._id + lineItem.hasDiscount"
          :lineItem="lineItem"
          :lineItemGroup="lineItemGroup"
          :shouldBlur="shouldBlur" />
        <LineItemGroupTotals :price="groupTotalsPrice" :lineItemGroup="lineItemGroup" :shouldBlur="shouldBlur" />
      </main>
    </template>
  </Accordion>
</template>
<script setup>
import { computed, inject, ref, watch } from 'vue'
import { lineItemGroupProps } from '@/helpers/props.js'
import Accordion from '@/components/atoms/Accordion/Accordion.vue'
import LineItem from '@/components/organisms/Cart/LineItem/LineItem.vue'
import LineItemGroupTotals from '@/components/organisms/Cart/LineItemGroup/Totals/Totals.vue'
import { parse } from '@/helpers/lineItem.js'

const props = defineProps({
  lineItemGroup: lineItemGroupProps
})
const accordion = ref(null)
const cartStore = inject('cartStore')

const settings = inject('settings', {})
const hasLines = computed(() => cartStore.lines[props.lineItemGroup.type].lines.length)
const groupTotalsPrice = computed(() => {
  if (settings.isUseSimulatedPrice) {
    return parse(cartStore.lines[props.lineItemGroup.type]?.simulatedTotals?.price_total ?? 0)
  }
  return parse(cartStore.lines[props.lineItemGroup.type].totals.price)
})
const shouldClose = (group) => !group.checked && accordion.value.isOpen()
const shouldOpen = (group) => group.checked && !accordion.value.isOpen()

const shouldBlur = computed(() => !props.lineItemGroup.checked && settings.hasSimulatedPrices)

const handleChange = (isAccordionOpen) => {
  if (isAccordionOpen !== props.lineItemGroup.checked) {
    props.lineItemGroup.checked = isAccordionOpen
  }
}

watch(props.lineItemGroup, (group) => {
  if (shouldClose(group)) {
    accordion.value.close()
  } else if (shouldOpen(group)) {
    accordion.value.open()
  }
})

</script>
