<template>
  <Suspense>
    <MiniBasket />
  </Suspense>
  <CartPage @order:submit="orderSubmit" />
  <SuccessPage :submit-data="successData" />
</template>
<script>
import { createApp, ref } from 'vue'
import i18n, { initTranslations } from './i18n'
import magentoSettings from './stores/magentoSettings.js'
import cartStore from './stores/cart.js'
import CartPage from './components/pages/CartPage.vue'
import SuccessPage from './components/pages/SuccessPage.vue'
import MiniBasket from './components/MiniBasket.vue'
import {
  orderCart as orderCartFromService
} from './service/cartService.js'
import { callbackFallbackWithHTTPResponse } from './utils.js'
import * as myWindow from './helpers/browser.js'

/**
 * @typedef Product
 * @type {object}
 */

const cartmanChannel = new BroadcastChannel('cartman')
let updating = false

cartmanChannel.onmessage = async (messageEvent) => {
  const event = JSON.parse(messageEvent.data)
  if (event.type === 'update_cart') {
    updating = true
    await cartStore.actions.updateActiveAddresses({ fresh: false })
    cartStore.updateNeeded = false
    updating = false
    // eslint-disable-next-line no-console
    console.info('broadcast: cart has updated')
  }
}

const App = {
  name: 'App',
  props: {
    apiKey: String
  },
  emits: ['update:successData'],
  components: {
    MiniBasket,
    CartPage,
    SuccessPage
  },
  mounted: initTranslations,
  methods: {
    orderSubmit (value) {
      this.successData = value
      if (cartStore.isModalOpen) cartStore.toggleCartModal()
      if (!cartStore.isSuccessModalOpen) cartStore.toggleSuccessModal()
    }
  },
  setup () {
    const successData = ref({})

    return {
      successData
    }
  },
  created () {
    this.$watch(() => cartStore.updateNeeded, () => {
      if (updating === false && cartStore.updateNeeded === true) {
        cartmanChannel.postMessage(JSON.stringify({ type: 'update_cart' }))
        cartStore.updateNeeded = false
        // eslint-disable-next-line no-console
        console.info('broadcast: cart changed')
      }
    })
  }
}

export default App

async function handleResponseData (response) {
  cartStore.lastResult = response.clone()
  return response
}

// To the outside, this all becomes accessible at `window.cartman`
const cartman = myWindow.assignToWindow({
  init: async function (id, config) {
    // Preserve correct `this` scope of incoming function
    magentoSettings.fireGtmEvent = (eventName, eventPayload) => config.fireGtmEvent(eventName, eventPayload)
    magentoSettings.fetchToken = () => config.fetchToken()
    magentoSettings.fetchTranslations = (...phrases) => config.fetchTranslations(...phrases)
    magentoSettings.fetchSettings = async () => {
      if (config.fetchSettings) {
        const settings = await config.fetchSettings()
        magentoSettings.parseSettings(settings)
      }
    }

    // Let's wait for settings to be in before we render anything
    await magentoSettings.fetchSettings()
    cartStore.actions.wakeup()

    createApp(App)
      .provide('cartStore', cartStore)
      .provide('settings', magentoSettings)
      .use(i18n)
      .mount(id)
  },
  /**
   * Add a product to the basket
   *
   * @param {Product} product The product to add to the basket
   * @param {Function} [callback] Optional callback method (if you don't like promises)
   * @returns {Promise<string>} Resolves to result message
   */
  addProduct: function (product, callback) {
    return this.addProducts([product], callback)
  },
  /**
   * Add products to the basket
   *
   * @param {Product[]} products The product to add to the basket
   * @param {Function} [callback] Optional callback method (if you don't like promises)
   * @returns {Promise<string>} Resolves to result message
   */
  addProducts: async function (products, callback) {
    return cartStore.actions.addOrderLines(products.map((product) => ({
      ...product,
      factor_margin: magentoSettings.factorMargin,
      product_tax: magentoSettings.productTax
    })))
      .then((response) => callbackFallbackWithHTTPResponse(response, callback))
      .then(handleResponseData)
  },
  /**
   * Convert a basket into an order.
   *
   * @param {any} orderId The order-id by which to start tracking this
   * @param {Function=} callback Optional callback function, in case you hate Promises
   */
  orderCart: async function (orderId, callback) {
    return orderCartFromService(orderId)
      .then((response) => callbackFallbackWithHTTPResponse(response, callback))
      .then(handleResponseData)
  },
  /**
   * Register a handler to catch 'popstate' events, caused by calls to
   * `window.history`. This handler will open and close the cart in
   * accordance to the `event.state`, thus matching expected behavior
   * when using the browser back/forward buttons.
   */
  registerStateListener: function () {
    if (cartman && !cartman.stateHandler) {
      cartman.stateHandler = (event) => {
        if (!event.state && cartman.isReloadPage) {
          myWindow.location.reload()
          return
        }
        myWindow.requestAnimationFrame(() => cartStore.toggleCartModal(event.state?.cartModal ?? false))
      }
      myWindow.addEventListener('popstate', cartman.stateHandler)
    }
  },
  getVersion: function () {
    // eslint-disable-next-line no-console
    console.log('Cartman version: ', process.env.VUE_APP_VERSION)
  },
  /**
   * Force cart to open, for instance for use on cart-page.
   */
  openCart: function () {
    cartman.isReloadPage = true
    // To allow currently running processes some time to finish/render
    myWindow.requestAnimationFrame(() => cartStore.toggleCartModal(true))
  },
  emptyCart: async function () {
    return cartStore.emptyCart()
  },
  getApiState: function () {
    return cartStore.actions.getServiceStatus()
      // eslint-disable-next-line no-console
      .then(response => console.log(response))
  },
  isReloadPage: false
}, 'cartman')

cartman.registerStateListener()

</script>
<style lang="scss">
@import "~@ponbike/portal-css/src/cartman.scss";
</style>
