<template>
  <div v-if="showIcons" class="line-item__icons" data-testid="line-item__icons">
    <DiscountIcon v-for="icon in discountIcons" :icon="icon" />
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { lineItemProps } from '@/helpers/props'
import { getDiscountIcons } from '@/helpers/lineItem.js'
import DiscountIcon from './DiscountIcon/DiscountIcon.vue'

const props = defineProps({
  lineItem: lineItemProps,
  showRoomMode: Boolean
})

const discountIcons = computed(
  () => getDiscountIcons(props.lineItem).concat(getDiscountIcons(props.lineItem.subLine ?? { discountIcon: false }))
)
const showIcons = computed(() => discountIcons.value.length > 0)

</script>
