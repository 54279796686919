import { createI18n } from 'vue-i18n'
import magentoSettings from '@/stores/magentoSettings'
import * as constants from './constants'

export function collectPhrases () { return Object.values(constants) }

export async function initTranslations () {
  const translations = await fetchTranslations()
  setLocale(magentoSettings.locale)
  setTranslations(magentoSettings.locale, translations)
}

export async function fetchTranslations () {
  const result = {}
  const translations = await magentoSettings.fetchTranslations(...collectPhrases())
  translations.translations.forEach((row) => {
    result[row.original_string] = row.translated_string
  })

  return result
}

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: magentoSettings.locale,
  messages: {}
})

export const translate = i18n.global.t

export function setLocale (locale) {
  i18n.global.locale.value = locale
}

export function setTranslations (locale, messages) {
  i18n.global.setLocaleMessage(locale, messages)
}

export default i18n
