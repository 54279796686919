<template>
  <span class="badge badge--small badge--discount"
        v-if="showBadge"
        data-testid="discount-badge">{{ label }}</span>
</template>
<script setup>
import { useSettings } from '@/stores/magentoSettings'
import { computed } from '@vue/reactivity'

const props = defineProps({
  productGroup: String,
  label: {
    type: String,
    required: true
  }
})

const settings = useSettings()

const showBadge = computed(() => settings.isUseSimulatedPrice && props.label)
</script>
