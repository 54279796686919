import { computed } from 'vue'

export default () => {
  const company = (address) => computed(() => `${address.company} (${erpAddressId(address).value})`)
  const erpAddressId = (address) => computed(() => {
    const attribute = address?.custom_attributes.find(o => o.attribute_code === 'erp_address_id')
    const id = attribute?.value ?? ''

    return `${id}`
  })
  const houseNumber = (address) => computed(() => `${address.street[1] ?? ''}${address.street[2] ?? ''}`)
  const street = (address) => computed(() => `${address.street[0] ?? ''} ${houseNumber(address).value}`)
  const streetCity = (address) => computed(() => `${street(address).value}, ${address.city}`)

  return {
    company,
    erpAddressId,
    houseNumber,
    street,
    streetCity
  }
}
