<template>
  <span class="badge badge--small"
        :class="[productGroupModifier(productGroup)]"
        v-if="sku"
        data-testid="sku-badge">{{ sku }}</span>
</template>
<script setup>
import { PRODUCT_GROUPS } from '@/constants/lineItem'

defineProps({
  productGroup: String,
  sku: {
    type: String,
    required: true
  }
})

const productGroupModifier = (productGroup) => {
  let modifier
  switch (productGroup) {
    case PRODUCT_GROUPS.bike:
      modifier = 'badge--bike'
      break
    case PRODUCT_GROUPS.accessory:
    case PRODUCT_GROUPS.part:
      modifier = 'badge--part'
      break
  }
  return modifier
}
</script>
