<template>
  <article class="cart-finalize-pane" data-testid="cart-finalize-pane">
    <h2 class="cart-finalize-pane__title text-body">{{ $t(FINALIZE) }}</h2>
    <LineItemGroupSelector class="cart-finalize-pane__section" />
    <SelectedAddress class="cart-finalize-pane__section" />
    <Totals class="cart-finalize-pane__section" />
    <a
      class="cart-finalize-pane__action"
      :class="{'cart-finalize-pane__action--in-progress': orderSimulateInProgress}"
      v-if="priceAndAvailability"
      @click.prevent="checkPrice"
      :title="isPricingAndAvailabilityAvailable?'':$t(PRICE_AVAILABILITY_NOT_AVAILABLE)"
      :disabled="orderSimulateInProgress || !isPricingAndAvailabilityAvailable"
      href="#">{{ $t(CHECK_PRICE_AVAILABILITY) }}</a>
    <!--a class="cart-finalize-pane__action" v-else href="#">{{ $t(CHECK_AVAILABILITY) }}</a-->
    <div class="cart-finalize-pane__section--error" v-if="cartStore.errorSku">
      {{ $t(SKU_ERROR, { sku: cartStore.errorSku }) }}
    </div>
    <div class="cart-finalize-pane__section--error" v-if="cartStore.selectedAddress?.active?.status === 'failed'">
      {{ $t(FAILED_CART) }}
    </div>
    <div class="cart-finalize-pane__section" :title="(cartBroken) ? $t(FAILED_ORDER_PRESENT) : ''">
      <p
        class="cart-finalize-pane__maintenance-message"
        v-if="!isPricingAndAvailabilityAvailable"
      >{{$t(PRICE_AVAILABILITY_NOT_AVAILABLE)}}</p>
      <button :disabled="submitDisabled" class="btn btn--primary btn--portal w-100" @click="placeOrder">
        {{ $t(PLACE_ORDER) }}
      </button>
    </div>
  </article>
</template>
<script setup>
import { computed, inject, ref } from 'vue'
import { CHECK_PRICE_AVAILABILITY, PLACE_ORDER, FINALIZE, SKU_ERROR, FAILED_ORDER_PRESENT, FAILED_CART, PRICE_AVAILABILITY_NOT_AVAILABLE } from '@/i18n/constants'
import useAddressFormat from '@/composables/useAddressFormat'
import LineItemGroupSelector from './LineItemGroupSelector/LineItemGroupSelector.vue'
import SelectedAddress from './SelectedAddress/SelectedAddress.vue'
import Totals from './Totals/Totals.vue'

const { erpAddressId } = useAddressFormat()

const settings = inject('settings', { customerRules: { isOrderSubmitAllowed: true } })
const cartStore = inject('cartStore')
const emit = defineEmits(['cart:submit'])

const isPricingAndAvailabilityAvailable = ref(settings.isPricingAndAvailabilityAvailable)

const showPrices = ref(settings.showPrices)
const isUseSimulatedPrice = ref(settings.isUseSimulatedPrice)
const priceAndAvailability = computed(() => isUseSimulatedPrice.value && showPrices.value)

const submitted = ref(false)

const selectedAddressId = erpAddressId(cartStore.selectedAddress).value

const cartBroken = computed(() => {
  const addressFiltered = cartStore.addresses
    .filter(address => erpAddressId(address).value === selectedAddressId)
  return (
    submitted.value ||
    cartStore.errorSku !== null ||
    (
      addressFiltered.length > 1 &&
      cartStore.selectedAddress?.active?.status !== 'failed'
    )
  )
})

const submitDisabled = computed(() => {
  if (settings.customerRules.isOrderSubmitAllowed === false) {
    return true
  }

  return cartBroken.value
})

const orderSimulateInProgress = ref(false)

async function checkPrice () {
  if (!orderSimulateInProgress.value && isPricingAndAvailabilityAvailable.value) {
    orderSimulateInProgress.value = true
    await cartStore.actions.updateOrderSimulate()
    orderSimulateInProgress.value = false
  }
}

async function placeOrder () {
  cartStore.actions.fireBeginCheckoutEvent()
  submitted.value = true
  const result = await cartStore.actions.submitCart()
  emit('cart:submit', result)
  cartStore.actions.fetchCartState()
}
</script>
