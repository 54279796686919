<template>
  <div class="cart-finalize-pane__address" v-if="cartStore.selectedAddress" data-testid="selected-address">
    <h6 class="cart-finalize-pane__address-title text-body mb-1">{{ $t(ADDRESS) }}</h6>
    <address class="cart-finalize-pane__selected-address text-muted mb-0">
      {{ company(cartStore.selectedAddress).value }}
      {{ streetCity(cartStore.selectedAddress).value }}
    </address>
  </div>
</template>
<script setup>
import { inject } from 'vue'
import useAddressFormat from '@/composables/useAddressFormat'
import { ADDRESS } from '@/i18n/constants'

const { company, streetCity } = useAddressFormat()
const cartStore = inject('cartStore')
</script>
